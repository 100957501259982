import React, { useState } from "react";
import "./Home.css";
import logo from "../Pages/logofirstAD.png";
import hero from "../Pages/OQF landscape.jpg";
import hero1 from "../Pages/Mobile.jpg";
import { db } from "../Firebase";

const Home = () => {
  const [email, setEmail] = useState("");
  const [referral, setReferral] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
  
    if (email.length === 0 || referral.length === 0) {
      window.alert("Please enter all fields!");
    } else if (!email.includes("@")) {
      alert("Please enter a valid email address!");
    }
  
    db.collection("ref-codes")
      .where("refCode", "==", referral)
      .onSnapshot((docs) => {
        if (docs.docs.length !== 0) {
          //ref code valid
  
          db.collection("Submission-details")
            .add({
              Email_id: email,
              referral_code: referral,
            })
            .then(() => {
              console.log("done");
              document.location.href =
                "http://bit.ly/FirstAdD2C";
            })
            .catch((error) => {
              alert(error.message);
            });
  
          setEmail("");
          setReferral("");
        } else {
          //ref code invalid
          window.alert("Invalid Referral code");
        }
      });
  };

  return (
    <div>
      <div className="card mb-3 background-container">
        <br />
        <br />

        <div className="card-body my_cardbody mx-auto" style={{ width: "80%" }}>
          {" "}
          <img
            id="img1"
            src={hero}
            className="card-img-top myimg mx-auto"
            alt="..."
          ></img>
          <img
            id="img2"
            src={hero1}
            className="card-img-top myimg mx-auto"
            alt="..."
          ></img>
          <br /> <br />
          <div className="title btn">
            <h1 className="card-title">Competition Details</h1>
          </div>
          <br /> <br />
          <h4>
            Dare2Compete <br />
            Online Quizzing Festival (OQF) Season 6
          </h4>
          <p className="card-text text-center">
            <strong>Event details </strong> <br />
            OQF Season 6, the worlds’ largest online quizzing festival organized
            by Dare2Compete brings to you 70+ quizzes from premier institutes
            like IIMs, IITs, NITs, and more! This contest has whopping prize
            money of USD 1500+, OQF 2021 is ready to set the stage on fire. Test
            your mettle and give a chase to the sharpest minds of the Nation.
            The clock is ticking! Buckle up for OQF season 6 and apply before
            it's too late.
            <br />
            <br />
          </p>
          <br />
          <div className="text-center container">
            <button
              className="btn mybtn text-white"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              Participate Now!
            </button>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <img className="mr-4" src={logo} width="30rem"></img>
              <h5 className="modal-title" id="exampleModalLabel">
                Fill Details
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="mb-3 row">
                  <div className="col-sm-10">
                    <input
                      type="email"
                      className="form-control"
                      id="staticEmail"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    ></input>
                  </div>
                </div>
                <div className="mb-3 row">
                  <div className="col-sm-10">
                    <input
                      type="text"
                      className="form-control"
                      id="inputReferral"
                      placeholder="Referral Code"
                      value={referral}
                      onChange={(e) => setReferral(e.target.value)}
                    ></input>
                  </div>
                </div>
                <p>
                  <strong>
                    Note:- Make sure that the above filled details must be true
                    to the best of your knowledge
                  </strong>
                </p>
                <button
                  type="submit"
                  className="mybtn btn text-white"
                  style={{ textAlign: "center" }}
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
