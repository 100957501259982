import firebase from "firebase";

var firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyDqjlmffv8EBUmk5I0RwWGr8DLuQi9R-j8",
    authDomain: "yourfirstad-events-21d86.firebaseapp.com",
    projectId: "yourfirstad-events-21d86",
    storageBucket: "yourfirstad-events-21d86.appspot.com",
    messagingSenderId: "445207937351",
    appId: "1:445207937351:web:21830da64c3f8d076ecc62"
});

var db = firebaseApp.firestore();
var auth = firebase.auth();
var storage = firebase.storage()

export {db,auth,storage};